<template>
  <div class="queryDetails">
    <!-- 企业日历 -->
    <van-nav-bar
      :title="title"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="overflow_div">
      <!-- 查询条件--日期 -->
      <div class="date_content">
        <div class="year_box">
          <div class="icon_box" @click="arrowsYear('left')">
            <van-icon name="arrow-left" />
          </div>
          <div
            class="year_btn"
            v-for="(it, idx) in years"
            :key="idx"
            :class="[
              1 == 1 ? 'cao' + idx : '',
              checkedYear == it ? 'checkd_year' : ''
            ]"
            @click="getOneYear(it)"
          >
            <div class="year">{{ it + '年' }}</div>
          </div>
          <div class="icon_box" @click="arrowsYear('right')">
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="month_box">
          <div class="month_btn" v-for="(it, idx) in months" :key="idx">
            <div
              class="month"
              :class="checkedMonth == it ? 'checkd_Month' : ''"
              @click="getOneMonth(it)"
            >
              {{ it + '月' }}
            </div>
          </div>
        </div>
      </div>
      <div class="table_content">
        <vue-hash-calendar
          :visible="true"
          :pickerType="'date'"
          format="YY/MM/DD"
          :defaultDatetime="selectDate"
          :showTodayButton="false"
          :isShowAction="false"
          :markDate="markDate"
          :min-date="nminDate"
          :max-date="nmaxDate"
          :disabledWeekView="true"
          :markType="'circle'"
        >
          <div slot="day" slot-scope="scope" :style="getcolor(scope)">
            <div class="day">{{ scope.date.day }}</div>
            <div class="lunar">{{ lunar(scope) }}</div>
          </div>
        </vue-hash-calendar>
        <div class="resttypebox">
          <div
            class="resttype"
            v-for="(it, idx) in resttype"
            :key="idx"
            :style="{ color: it.color }"
          >
            <span :style="{ background: it.color }"></span> {{ it.name }}
          </div>
        </div>
        <div class="remark">备注说明：{{ remark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { parseTime } from '@/utils'
import { GetFilterDropDownList, getEmpCalendarInfo } from '@api/wx.js'
import { getLunar } from 'chinese-lunar-calendar'
export default {
  data() {
    const fromH5 = this.$route.query.fromH5
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const param = this.$route.query.param
    return {
      userInfo,
      param, // 从外部过来的参数
      fromH5, // 是否从H5过来的
      title: '', //传入的标题
      remark: '', //备注
      years: [], // 年份数组
      checkedYear: '', // 选中的年份
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // 月份数组
      checkedMonth: '', // 选中的月份
      loading: false,
      langid: 0, // 当前多语言Id
      lastYYMM: '', // 最后一次搜索的yymm
      resttype: [],
      markDate: [],
      nminDate: this.dd('min'),
      nmaxDate: this.dd('max'),
      selectDate: new Date(),
      getData: [],
      c: [
        { color: '#FF5175', bg: '#FFE2E8' },
        { color: '#2F85FF', bg: '#E8F2FF' },
        { color: '#FF7E00', bg: '#FFE8D2' },
        { color: '#B665FF', bg: '#F3E7FF' },
        { color: '#E52C2C', bg: '#F5CFC8' },
        { color: '#E53CC0', bg: '#F5D3F0' },
        { color: '#836608', bg: '#F0E0A8' },
        { color: '#2F6E55', bg: '#B7E7D5' },
        { color: '#007F01', bg: '#C1F6C3' },
        { color: '#079387', bg: '#C6F7F3' },
        { color: '#006DA2', bg: '#B8D8E7' },
        { color: '#4444B2', bg: '#D3E0FF' },
        { color: '#96607A', bg: '#E2BECB' },
        { color: '#BF7E6C', bg: '#E7D3D1' }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      GetFilterDropDownList({
        moduleno: 603,
        username: this.userInfo.username,
        listsql: '@休假类别'
      }).then(res => {
        res.data.forEach((e, i) => {
          this.resttype.push({
            name: e.dicvalue,
            color: this.c[i % 10].color,
            bg: this.c[i % 10].bg
          })
        })

        // 获取年
        this.getYearsByDate(new Date())
        // 第一次进来默认当前日期
        this.checkedMonth = new Date().getMonth() + 1
        if (this.fromH5 == true || this.fromH5 == 'true') {
          // console.log('H5过来的');
          this.empid = this.userInfo.empid
          this.langid = localStorage.getItem('langid')
          this.getAllPageDatas('')
        } else {
          // console.log('外部过来的');
          this.empid = this.$route.query.empid
          this.langid = this.$route.query.langid
          this.param = this.$route.query.param
          // 查询页面
          this.getAllPageDatas(this.param)
        }
      })
    },
    // 根据日期获取年份列表
    getYearsByDate(v) {
      if (v) {
        let date = new Date(v)
        let year = date.getFullYear()
        this.years = [year - 1, year, year + 1]
        //
        this.checkedYear = year
      }
    },
    // 不够就补零
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate(date, rule) {
      if (date) {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd') {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else {
        return ''
      }
    },
    // 返回
    onClickLeft() {
      if (this.$route.query.from == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/personCen')
      }
    },
    // 点击选择某个月份
    getOneMonth(v) {
      if (this.checkedMonth == v && false) {
        this.checkedMonth = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else {
        this.checkedMonth = v
        if (this.checkedYear) {
          this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
          // 查询页面
          this.getAllPageDatas(this.param)
        }
      }
    },
    // 点击选择某个年份
    getOneYear(v) {
      if (this.checkedYear == v && false) {
        this.checkedYear = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else {
        this.checkedYear = v
        this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
        // 查询页面
        this.getAllPageDatas(this.param)
      }
    },
    // 箭头切换年份
    arrowsYear(v) {
      if (v == 'left') {
        this.years.unshift(this.years[0] - 1)
        this.years.pop()
      } else {
        this.years.push(this.years[2] + 1)
        this.years.shift()
      }
    },
    getAllPageDatas(yymm) {
      const y = yymm || this.checkedYear + '' + this.add0(this.checkedMonth)
      getEmpCalendarInfo({
        yymm: y,
        empcode: this.userInfo.empcode
      }).then(res => {
        this.selectDate = new Date(
          y.substring(0, 4) + '/' + y.substring(4) + '/01'
        )

        this.title = res.baseinfo[0].title
        this.remark = res.baseinfo[0].remark
        this.getData = res.data
        let resttypearr = [],
          arr = []
        res.data.forEach(e => {
          if (resttypearr.indexOf(e.resttype) == -1) {
            resttypearr.push(e.resttype)
            this.resttype.forEach(k => {
              if (k.name == e.resttype) {
                arr.push({ color: k.bg, date: [] })
              }
            })
          }
          arr[resttypearr.indexOf(e.resttype)].date.push(
            e.yymmdd.replace(/-/g, '/')
          )
        })
        this.markDate = arr

        this.$nextTick(() => {
          const dateContent1 = document.getElementsByClassName('calendar_day')
          dateContent1.forEach(e => {
            let hasclass = false
            if (e.classList) {
              hasclass = e.classList.contains('calendar_day_checked')
            }
            // 修改样式
            e.style['background-color'] = '#fff'
          })

          const dateContent = document.getElementsByClassName(
            'calendar_mark_circle'
          )
          // console.log(dateContent, 'dateContent')
          dateContent.forEach(e => {
            // 修改样式
            e.style['background-color'] = e.style['border-color']
          })
        })
      })
    },
    //日期控件的最大日期/最小日期
    dd(m) {
      const d = new Date()
      const year = d.getFullYear()
      const month = d.getMonth()
      const day = d.getDate()
      if (m === 'min') {
        return new Date(year - 100, month, day)
      } else {
        return new Date(year + 30, month, day)
      }
    },
    // 农历+节假日
    lunar(scope) {
      const getLunarDay = getLunar(
          scope.date.year,
          scope.date.month + 1,
          scope.date.day
        ),
        yymmdd = parseTime(
          new Date(scope.date.year, scope.date.month, scope.date.day),
          '{y}-{m}-{d}'
        )
      let v =
        scope.date.month == 0 && scope.date.day == 1
          ? '元旦'
          : scope.date.month == 4 && scope.date.day == 1
          ? '劳动节'
          : scope.date.month == 9 && scope.date.day == 1
          ? '国庆'
          : scope.date.month == 2 && scope.date.day == 8
          ? '妇女节'
          : getLunarDay.dateStr == '正月初一'
          ? '春节'
          : getLunarDay.dateStr == '正月十五'
          ? '元宵'
          : getLunarDay.dateStr == '五月初五'
          ? '端午'
          : getLunarDay.dateStr == '八月十五'
          ? '中秋'
          : getLunarDay.solarTerm
          ? getLunarDay.solarTerm
          : getLunarDay.dateStr.indexOf('初一') > -1
          ? getLunarDay.dateStr.substring(0, 2)
          : getLunarDay.dateStr.substring(2)
      for (let index = 0; index < this.getData.length; index++) {
        const e = this.getData[index]
        if (e.yymmdd == yymmdd) {
          v = e.reststyle || e.resttype
          break
        }
      }
      return v
    },
    getcolor(scope) {
      let style = {}
      const yymmdd = parseTime(
        new Date(scope.date.year, scope.date.month, scope.date.day),
        '{y}-{m}-{d}'
      )
      //  console.log(yymmdd,scope.date.month)
      let resttype = ''

      for (let index = 0; index < this.getData.length; index++) {
        const e = this.getData[index]
        if (e.yymmdd == yymmdd) {
          // console.log(resttype,e.yymmdd , yymmdd);
          resttype = e.resttype
          break
        }
      }

      if (resttype) {
        for (let index = 0; index < this.resttype.length; index++) {
          const e = this.resttype[index]
          if (e.name == resttype) {
            style = { color: e.color + ' !important' }
            break
          }
        }
      }
      return style
    }
  }
}
</script>

<style scoped lang="less">
.queryDetails {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 100px);
    .date_content {
      .year_box {
        display: flex;
        align-items: center;
        padding: 20px 0;
        background: #ffffff;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          width: calc((100% - 40px) / 3);
          color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background-color: #eff6ff;
          border-radius: 10px;
          .year {
            padding: 10px 0;
          }
        }
        .cao0 {
          margin: 0 20px 0 10px;
        }
        .cao1 {
          margin-right: 20px;
        }
        .cao2 {
          margin-right: 10px;
        }
        .checkd_year {
          color: #ffffff;
          background: #5398ff;
        }
      }
      .month_box {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        padding-bottom: 10px;
        .month_btn {
          width: calc(100% / 6);
          color: #aeaeae;
          font-size: 28px;
          text-align: center;
          .month {
            padding: 10px 0;
            margin: 10px;
          }
          .checkd_Month {
            color: #5095ff;
            background: #ffffff;
            border-radius: 30px;
            border: 1px solid #5095ff;
          }
        }
      }
      .date_box {
        .head_date {
          display: flex;
          align-items: center;
          padding: 20px 0;
          background: #fff;
          .icon_box {
            display: flex;
            align-items: center;
            .van-icon {
              font-size: 40px;
              font-weight: bold;
            }
          }
          .date_box {
            width: calc((100% - 40px) / 7);
            font-size: 28px;
            text-align: center;
            .week {
              margin-bottom: 10px;
            }
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              .text {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
              }
              .bgtext {
                color: #fff;
                background: #2b8df0;
              }
            }
          }
        }
      }
    }
    .table_content {
      position: relative;
      font-size: 28px;
      height: calc(100vh - 500px);
      overflow-y: auto;
      /deep/.calendar_inline {
        min-height: 2.02rem !important;
      }
      /deep/.hash-calendar {
        .calendar_content {
          min-height: 150px !important;
          .calendar_body {
            .calendar_week {
              font-size: 28px !important;
              font-family: Source Han Sans CN;
              color: #333;
            }
          }
        }
      }
      /deep/ .calendar_day {
        width: 1.2rem;
        height: 1.2rem;
        margin-bottom: 0.5vw !important;
        font-size: 28px !important;
        div {
          text-align: center;
        }
        .lunar {
          font-size: 24px;
        }
      }
      /deep/ .hash-calendar {
        height: 10rem !important;
        .calendar_content {
          height: 100% !important;
          .calendar_group {
            height: 8.5rem !important;
            top: 1.4rem !important;
            .calendar_item {
              height: 1.2rem !important;
              margin-bottom: 0.2rem;
              .calendar_day {
                margin-bottom: 0 !important;
              }
              .calendar_day_checked{
                background: #fff;
                color: #000;
              }              .calendar_dot {
                display: none;
              }
            }
          }
        }
      }
      .resttypebox {
        display: flex;
        flex-wrap: wrap;
        line-height: 40px;
        background: #ffffff;
        padding: 20px;
        .resttype {
          width: 25%;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          line-height: 40px;
          font-size: 28px;
          span {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: inline-block;
          }
        }
      }
      .remark {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #999999;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dfdfdf;
        padding: 20px;
        margin: 20px;
      }
    }
  }
}
</style>
